import * as React from 'react';
import moment from 'moment-timezone';
import 'moment-duration-format';

import DatePicker from 'components/date_picker';
import TimePicker from 'components/time_picker';
import DurationPicker from 'components/duration_picker';

const styles = require('./styles.scss');

interface FromToDateTimePickerProps {
  fromDateName: string;
  toDateName: string;
  timezone?: string;
  timezoneDisplay?: string;
  fromDate?: moment.Moment;
  toDate?: moment.Moment;
  fromDisabled: boolean;
  toDisabled: boolean;
  onDateChange: (fromDate: moment.Moment, toDate: moment.Moment) => void;
}

interface FromToDateTimePickerState {
  fromDate: moment.Moment;
  toDate: moment.Moment;
  duration: moment.Duration;
}

export default class FromToDateTimePicker extends React.PureComponent<
  FromToDateTimePickerProps,
  FromToDateTimePickerState
> {
  static defaultProps = {
    timezone: 'UTC',
    timezoneDisplay: 'UTC',
  };

  constructor(props: FromToDateTimePickerProps) {
    super(props);
    const fromDate = props
      .fromDate!.tz(props.timezone!)
      .second(0)
      .millisecond(0);
    const toDate = props
      .toDate!.tz(props.timezone!)
      .second(0)
      .millisecond(0);
    const duration = moment.duration(toDate.diff(fromDate));
    this.state = { fromDate, toDate, duration };
  }

  componentWillReceiveProps(nextProps: FromToDateTimePickerProps) {
    const { duration } = this.state;
    const fromDate = nextProps
      .fromDate!.tz(nextProps.timezone!)
      .second(0)
      .millisecond(0);
    const toDate = fromDate.clone().add(duration);

    this.setState({ fromDate, toDate });
  }

  changeFromValue = (fromDate: moment.Moment) => {
    const { duration } = this.state;
    const toDate = fromDate.clone().add(duration);
    this.props.onDateChange(fromDate, toDate);
  };

  durationChange = (duration: moment.Duration) => {
    const toDate = this.state.fromDate.clone().add(duration);
    this.setState({ toDate, duration });
    this.props.onDateChange(this.state.fromDate, toDate);
  };

  render() {
    const datepickerId = `datePickerid${new Date().getTime()}`;
    return (
      <div className={styles.dateTimePicker}>
        <div className={styles.label}>
          <label className={styles.hasADG3Color} htmlFor={datepickerId}>
            Scheduled Time
          </label>
          <small>Timezone: {this.props.timezoneDisplay}</small>
        </div>
        <div className={styles.field}>
          <input
            type="hidden"
            name={this.props.fromDateName}
            value={this.state.fromDate.toISOString()}
          />
          <input
            type="hidden"
            name={this.props.toDateName}
            value={this.state.toDate.toISOString()}
          />
          <div className={styles.doubleField}>
            <div className={styles.dateAndTimePickers}>
              <div className={styles.datePicker}>
                <DatePicker
                  id={datepickerId}
                  onChange={this.changeFromValue}
                  selectedDate={this.state.fromDate}
                  disabled={this.props.fromDisabled}
                />
              </div>
              <div className={styles.timePicker}>
                <TimePicker
                  onChange={this.changeFromValue}
                  selectedDate={this.state.fromDate}
                  disabled={this.props.fromDisabled}
                />
              </div>
            </div>
          </div>
          <div className={styles.smallField}>for</div>
          <div className={styles.doubleField}>
            <DurationPicker
              duration={this.state.duration}
              disabled={this.props.toDisabled}
              onChange={this.durationChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
