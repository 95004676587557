import * as React from 'react';

import Tooltip from 'components/tooltip';

export interface CheckboxProps {
  name: string;
  label?: JSX.Element | string;
  helpText?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  disabledReason?: string;
  retainCheckedState?: boolean; // Allows checkbox to retain its checked state when disabled.
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export default class Checkbox extends React.PureComponent<CheckboxProps> {
  get helpText() {
    return this.props.helpText ? (
      <div className="help-block">{this.props.helpText}</div>
    ) : null;
  }

  get renderDisabledNotificationExplanation() {
    const { disabled, disabledReason } = this.props;
    if (!disabled) {
      return;
    }
    if (!disabledReason) {
      return;
    }

    return (
      <Tooltip title={this.props.disabledReason || ''}>
        <a href="#" onClick={e => e.preventDefault()}>
          Why is this disabled?
        </a>
      </Tooltip>
    );
  }

  get isChecked() {
    if (typeof this.props.checked === 'boolean') {
      const { disabled, retainCheckedState, checked } = this.props;
      return disabled ? (retainCheckedState ? checked : false) : checked;
    }
    return undefined;
  }

  render() {
    const style: React.CSSProperties = { marginRight: '10px' };
    if (this.props.disabled) {
      style.textDecoration = 'line-through';
      style.color = '#d1d1d1';
    }

    return (
      <div className="controls">
        <label className="checkbox">
          <input type="hidden" name={this.props.name} value="0" />
          <input
            type="checkbox"
            name={this.props.name}
            defaultChecked={this.props.defaultChecked}
            checked={this.isChecked}
            disabled={this.props.disabled}
            onChange={this.props.onChange}
            value="1"
          />
          <span style={style}>{this.props.label}</span>
          {this.renderDisabledNotificationExplanation}
        </label>
        {this.helpText}
      </div>
    );
  }
}
