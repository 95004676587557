import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';
import { Checkbox } from '@atlaskit/checkbox';

export default class Item extends React.Component {
  static propTypes = {
    item: types.shape({
      id: types.string,
      isDisabled: types.bool,
      name: types.string,
      selected: types.bool,
    }).isRequired,
    toggle: types.func.isRequired,
  };

  toggleCheckbox = () => {
    const { item, toggle } = this.props;
    toggle(item);
  };

  render() {
    const {
      item: { id, isDisabled, name, selected },
    } = this.props;

    return (
      <div
        className={classNames('grouped-item', {
          active: selected,
        })}
      >
        <Checkbox
          className="atlaskit-checkbox-component"
          onChange={this.toggleCheckbox}
          isChecked={selected}
          isDisabled={isDisabled}
          label={
            <span className="grouped-item-label component-toggle">{name}</span>
          }
        />
        <span style={{ display: 'none' }}>{id}</span>
      </div>
    );
  }
}
