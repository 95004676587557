const STORAGE_KEY = 'statuspage.api.token';
const NEW_SESSION_COOKIE = 'statuspage_new_session';

class SessionStore {
  constructor() {
    // If this is a new session, clear out the session cache, to avoid weird session race conditions
    if (this._newSessionCookieSet()) {
      this.clear();
      this._clearNewSessionCookie();
    }
  }

  /**
   * Returns a session token
   * If the local sessionStorage doesn't have a token in memory,
   * get one from the remote session endpoint and return that.
   * @returns {Promise}
   */
  get() {
    const token = this._fetchLocalToken();
    if (token) {
      return Promise.resolve(token);
    } else {
      return this._fetchRemoteToken();
    }
  }

  /**
   * Clears the sessionStorage object.
   */
  clear() {
    window.sessionStorage.removeItem(STORAGE_KEY);
  }

  /**
   * Check to see if the new session cookie is set
   */
  _newSessionCookieSet() {
    const cookie = document.cookie
      .split('; ')
      .filter(str => ~str.indexOf(NEW_SESSION_COOKIE))[0];
    if (cookie && cookie.split('=')[1] === 'true') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Clears the new session cookie
   */
  _clearNewSessionCookie() {
    document.cookie = `${NEW_SESSION_COOKIE}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  /**
   * Makes a get request for a current session token
   * If a token is successfully returned, store it in sessionStorage.
   * Otherwise, reject the error.
   * @returns {Promise}
   */
  _fetchRemoteToken() {
    return new Promise((resolve, reject) => {
      $.ajax({
        contentType: 'application/json',
        url: Routes.current_session_path({ format: 'json' }),
        success: response => {
          const token = response.user.session_token;
          // Set the token to session storage
          this._setLocalToken(token);
          resolve(token);
        },
        error: reject,
      });
    });
  }

  /**
   * @returns {String|null} session token from sessionStorage
   */
  _fetchLocalToken() {
    return window.sessionStorage.getItem(STORAGE_KEY);
  }

  /**
   * @param {String} sessionToken Sets a token to sessionStorage
   */
  _setLocalToken(sessionToken) {
    window.sessionStorage.setItem(STORAGE_KEY, sessionToken);
  }
}

const sessionStore = new SessionStore();
export default sessionStore;
