import * as React from 'react';
import { GroupType, ItemType } from '@atlaskit/single-select';

export const StatusSelectValues: GroupType[] = [
  {
    items: [
      {
        elemBefore: (
          <i className="component-status page-colors text-color operational" />
        ),
        content: 'Operational',
        value: 'operational',
      },
      {
        elemBefore: (
          <i className="component-status page-colors text-color degraded_performance" />
        ),
        content: 'Degraded performance',
        value: 'degraded_performance',
      },
      {
        elemBefore: (
          <i className="component-status page-colors text-color partial_outage" />
        ),
        content: 'Partial outage',
        value: 'partial_outage',
      },
      {
        elemBefore: (
          <i className="component-status page-colors text-color major_outage" />
        ),
        content: 'Major outage',
        value: 'major_outage',
      },
      {
        elemBefore: (
          <i className="component-status page-colors text-color under_maintenance" />
        ),
        content: 'Under maintenance',
        value: 'under_maintenance',
      },
    ],
  },
];

export const StatusSelectorHash = StatusSelectValues[0].items.reduce(
  (hash, item) => {
    hash[item.value!] = item;
    return hash;
  },
  {} as { [key: string]: ItemType },
);
