import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import Header from './header';
import Footer from './footer';
import ItemsCollection from './items_collection';

export default class ItemsSelector extends React.Component {
  static propTypes = {
    className: PropTypes.string, // class name applied to the container div
    hiddenFieldId: PropTypes.string, // id attached to the hidden input
    hiddenFieldName: PropTypes.string, // name attached to the hidden input
    items: PropTypes.arrayOf(PropTypes.object).isRequired, // A collection of items to display.  Minimum required field is `name`
    onClose: PropTypes.func, // a function that will be invoked when the modal is closed
    onToggle: PropTypes.func, // a function that will be invoked when an item is toggled
    preselectedItemIds: PropTypes.oneOfType([
      // A collection of preselected item ids
      PropTypes.string,
      PropTypes.array,
    ]),
    presentSelectorClickTargetId: PropTypes.string, // We'll attach a listener to the supplied id that toggles visibility
    showHeader: PropTypes.bool, // boolean to determine header visibility
    showFooter: PropTypes.bool, // boolean to determine footer visibility
    submitTo: PropTypes.func, // i honestly have no idea what this is.
    toggleAllclickTargetId: PropTypes.string, // handle for the toggle all switch, external to the component.
    showTooltipDescriptions: PropTypes.bool, // boolean to determine display of description tooltip next to each item
  };

  static defaultProps = {
    className: null,
    hiddenFieldName: null,
    hiddenFieldId: null,
    onClose: () => {},
    onToggle: () => {},
    preselectedItemIds: [],
    presentSelectorClickTargetId: null,
    showHeader: false,
    showFooter: false,
    submitTo: () => {},
    toggleAllclickTargetId: null,
    showTooltipDescriptions: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      hasGroupedItems: false,
      visible: !props.presentSelectorClickTargetId,
    };
  }

  componentDidMount() {
    $(document.body).on('items-selector-choose', this.selectItems);
    const {
      items,
      presentSelectorClickTargetId,
      toggleAllclickTargetId,
    } = this.props;
    this.setState({ items: this.structureItemsForDisplay(items) });
    if (toggleAllclickTargetId) {
      document
        .getElementById(toggleAllclickTargetId)
        .addEventListener('click', this.toggleAllItems);
    }
    if (presentSelectorClickTargetId) {
      document
        .getElementById(presentSelectorClickTargetId)
        .addEventListener('click', this.togglePresentSelector);
    }
  }

  componentDidUpdate() {
    this._dispatchEvent();
  }

  componentWillUnmount() {
    $(document.body).off('items-selector-choose', this.selectItems);
    const { presentSelectorClickTargetId, toggleAllclickTargetId } = this.props;
    if (toggleAllclickTargetId) {
      document
        .getElementById(toggleAllclickTargetId)
        .removeEventListener('click', this.toggleAllItems);
    }
    if (presentSelectorClickTargetId) {
      document
        .getElementById(presentSelectorClickTargetId)
        .removeEventListener('click', this.togglePresentSelector);
    }
  }

  selectItems = (el, opts) => {
    const { items } = this.state;
    const tempItems = items.map((item) => {
      if (item.group) {
        return {
          ...item,
          showChildren: false,
          children: item.children.map((child) => ({
            ...child,
            selected: opts.items.indexOf(child.id) !== -1,
            showChildren: item.showChildren || child.selected,
          })),
        };
      }
      return {
        ...item,
        selected: opts.items.indexOf(item.id) !== -1,
      };
    });

    this.setState({ items: tempItems });
  };

  submit = () => {
    const { submitTo } = this.props;
    if (submitTo) {
      window.location = `${submitTo}${this._getSelectedIds().join(',')}`;
    } else {
      this.setState((prevState) => ({ visible: !prevState.visible }));
    }
  };

  /**
   * Since our items come in as a flat list, we need to build the nested structure for grouped hierarchies
   * @returns {Array}
   */
  structureItemsForDisplay = (flatItems) => {
    const { preselectedItemIds } = this.props;
    const items = [];
    let hasGroupedItems = false;

    flatItems.forEach((groupedItem) => {
      groupedItem.children = flatItems.filter(
        (item) => item.group_id === groupedItem.id,
      );
      let childSelected = false;

      groupedItem.children.forEach((child) => {
        hasGroupedItems = true;
        child.selected = preselectedItemIds.indexOf(child.id) > -1;
        if (child.selected) {
          childSelected = true;
        }
      });

      // We want to ensure group parents are not being selected.
      if (groupedItem.children.length <= 0) {
        groupedItem.selected = preselectedItemIds.indexOf(groupedItem.id) > -1;
      }

      groupedItem.showChildren = childSelected;
      if (!groupedItem.group_id) {
        items.push(groupedItem);
      }
    });

    this.setState({ hasGroupedItems });

    return items;
  };

  /**
   * Toggle one or many items' selected state.  If forceValue is passed, set all items to that value.
   * @param items
   * @param {boolean} forceValue
   */
  toggleItems = (items, forceValue = null) => {
    if (!items || items.length === 0) return;
    const targetItems = Array.isArray(items) ? items : new Array(items);

    targetItems.forEach((item) => {
      let index;
      if (item.group_id) {
        // this is a child component, so we need to find the group and target its items
        const tempItems = [...this.state.items];
        index = tempItems.findIndex((i) => i.id === item.group_id);
        for (const child of tempItems[index].children) {
          if (child.id === item.id) {
            child.selected = forceValue || !child.selected;
            break;
          }
        }
        this.setState({ items: tempItems }, () =>
          this.props.onToggle(this.state.items),
        );
      } else {
        // standard component
        const tempItems = [...this.state.items];
        index = tempItems.findIndex((i) => i.id === item.id);
        tempItems[index].selected = !tempItems[index].selected;
        this.setState({ items: tempItems }, () =>
          this.props.onToggle(this.state.items),
        );
      }
      this._dispatchEvent();
    });
  };

  /**
   * Toggle all items' selected state
   */
  toggleAllItems = () => {
    let groupedItemCount = 0;
    let selectedCount = 0;

    const tempItems = [...this.state.items];

    tempItems.forEach((groupedItem) => {
      if (groupedItem.children.length > 0) {
        groupedItem.children.forEach((child) => {
          groupedItemCount++;
          if (child.selected) selectedCount++;
        });
      } else {
        groupedItemCount++;
        if (groupedItem.selected) selectedCount++;
      }
    });

    const newValue = selectedCount !== groupedItemCount;
    tempItems.forEach((groupedItem) => {
      if (groupedItem.children.length > 0) {
        groupedItem.children.forEach((child) => {
          child.selected = newValue;
        });
      } else {
        groupedItem.selected = newValue;
      }
    });

    this.setState({ items: tempItems }, () =>
      this.props.onToggle(this.state.items),
    );
    this._dispatchEvent();
  };

  clearAllItems = () => {
    const { items } = this.state;
    const clearedItems = items.map((item) => {
      if (item.children.length > 0) {
        return {
          ...item,
          children: item.children.map((child) => ({
            ...child,
            selected: false,
          })),
        };
      }
      return {
        ...item,
        selected: false,
      };
    });

    this.setState({ items: clearedItems }, () => {
      const { onToggle: handleToggle } = this.props;
      handleToggle(items);
    });
  };

  /**
   * Currently selected items.
   * @returns {Array}
   */
  getSelectedItems = () => {
    const selectedItems = [];
    this.state.items.forEach((item) => {
      if (item.children.length > 0) {
        // It's a group!
        item.children.forEach((child) => {
          if (child.selected) selectedItems.push(child);
        });
      } else if (item.selected) {
        // It's a flat item
        selectedItems.push(item);
      }
    });
    return selectedItems;
  };

  renderHiddenField = () => {
    const { hiddenFieldId, hiddenFieldName } = this.props;
    const componentValues = this.getSelectedItems()
      .map((item) => item.id)
      .join(',');

    return (
      <input
        type="hidden"
        id={hiddenFieldId}
        name={hiddenFieldName}
        value={componentValues}
      />
    );
  };

  togglePresentSelector = () => {
    const { onClose: handleClose } = this.props;
    if (typeof handleClose === 'function') {
      handleClose();
    }
    this.setState((prevState) => ({ visible: !prevState.visible }));
  };

  _getSelectedIds = () =>
    this.getSelectedItems().reduce((acc, item) => acc.concat(item.id), []);

  _dispatchEvent = () => {
    // This crap is because we only need to do this on pages that
    // are listening with jQuery.
    if (typeof jQuery !== 'undefined') {
      $(document.body).trigger('items-selector-changed', {
        selectedIds: this._getSelectedIds(),
      });
    }
  };

  render() {
    const {
      className,
      hiddenFieldName,
      showFooter,
      showHeader,
      showTooltipDescriptions,
    } = this.props;
    const { hasGroupedItems, items, visible } = this.state;
    return (
      <div
        className={cx('grouped-items-selector', 'border-color', className)}
        style={{ display: !visible ? 'none' : null }}
      >
        <Header show={showHeader} />

        <ItemsCollection
          items={items}
          toggleItems={this.toggleItems}
          hasGroupedItems={hasGroupedItems}
          showTooltipDescriptions={showTooltipDescriptions}
        />

        {hiddenFieldName ? this.renderHiddenField() : null}

        <Footer
          show={showFooter}
          onSubmit={this.submit}
          onClose={this.togglePresentSelector}
          onClear={this.clearAllItems}
        />
      </div>
    );
  }
}
