import * as React from 'react';
import * as ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import cx from 'classnames';
import 'moment-duration-format';

import Calendar from '@atlaskit/calendar';

const styles = require('./styles.scss');

interface Props {
  onChange: (value: moment.Moment) => void;
  selectedDate: moment.Moment;
  isValid?: string;
  disabled?: boolean;
  name?: string;
  id: string;
}

interface State {
  showCalendar: boolean;
  month: number;
  selectedDate: moment.Moment;
  year: number;
  inputValue: string;
}

export default class DatePicker extends React.Component<Props, State> {
  id: string;

  static defaultProps: Partial<Props> = {
    disabled: false,
  };

  constructor(props: Props) {
    super(props);
    this.id = props.id || `datePickerid${new Date().getTime()}`;
    this.state = {
      showCalendar: false,
      selectedDate: props.selectedDate,
      month: props.selectedDate.month() + 1,
      year: props.selectedDate.year(),
      inputValue: props.selectedDate.format('YYYY-MM-DD'),
    };
  }
  componentWillReceiveProps(props: Props) {
    if (props.selectedDate) {
      this.setState({
        selectedDate: props.selectedDate,
        inputValue: props.selectedDate.format('YYYY-MM-DD'),
      });
    }
  }

  hideCalendar = () => {
    this.setState({ showCalendar: false });
  };

  showCalendar = () => {
    this.setState({ showCalendar: true });
  };

  selectValue = (val: { year: number; month: number; day: number }) => {
    // if input is active on mobile, blur to hide keyboard
    const active = document.activeElement;
    if (active instanceof HTMLElement) {
      active.blur();
    }

    const date = this.props.selectedDate.clone();
    date.year(val.year);
    date.month(val.month - 1); // moment months are 0 indexed (0 = Jan)
    date.date(val.day);
    this.hideCalendar();
    this.props.onChange(date);
  };

  changeValue = (val: { year: number; month: number }) => {
    this.setState({
      month: val.month,
      year: val.year,
    });
  };

  toggleCalendar = () => {
    if (this.props.disabled) {
      return;
    }
    this.setState({
      showCalendar: !this.state.showCalendar,
    });
  };

  calendar = () => {
    return !this.state.showCalendar ? (
      undefined
    ) : (
      <div className={styles.calendarWrapper}>
        <Calendar
          onBlur={this.hideCalendar}
          onSelect={this.selectValue}
          onChange={this.changeValue}
          month={this.state.month}
          year={this.state.year}
          selected={[this.state.selectedDate.format('YYYY-MM-DD')]}
        />
      </div>
    );
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    document.addEventListener('keydown', this.handleEsc);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.removeEventListener('keydown', this.handleEsc);
  }

  handleEsc = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      this.hideCalendar();
    }
  };

  handleClickOutside = (event: Event): void => {
    if (this.state.showCalendar) {
      const domNode = ReactDOM.findDOMNode(this);
      if (
        !domNode ||
        (event.target instanceof Node && !domNode.contains(event.target))
      ) {
        this.hideCalendar();
      }
    }
  };

  validation = () => {
    if (!this.props.isValid) {
      return null;
    }
  };

  onFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
    if (event.target) {
      event.target.select();
    }
  };

  onBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    this.setState({ inputValue: this.props.selectedDate.format('YYYY-MM-DD') });
  };

  onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;
    this.setState({ inputValue });
    if (
      inputValue.match(/^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/)
    ) {
      const date = moment(inputValue, 'YYYY-MM-DD');
      // preserve hour and minutes from previous moment
      date.tz(this.props.selectedDate.tz() || 'Etc/UTC');
      date.hour(this.props.selectedDate.hour());
      date.minute(this.props.selectedDate.minute());
      if (!date.isValid()) {
        return;
      }
      this.props.onChange(date);
    }
  };

  render() {
    return (
      <div className={styles.calendarParentPosition}>
        <div className={styles.datePicker}>
          <label htmlFor={this.id} className={styles.prepend}>
            <span className="fa fa-calendar" />
          </label>
          <input
            id={this.id}
            name={this.props.name}
            className={cx('date-input', styles.dateInput)}
            disabled={this.props.disabled}
            value={this.state.inputValue}
            onClick={this.toggleCalendar}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          {this.validation()}
        </div>
        <div className={styles.calendarFloater}>{this.calendar()}</div>
      </div>
    );
  }
}
