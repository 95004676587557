import React from 'react';

class Footer extends React.Component {
  submitButton() {
    return (
      <a
        className="flat-button pull-right submit-button"
        onClick={this.props.onSubmit}
      >
        {this.props.submitText || 'Submit'}
      </a>
    );
  }

  closeButton() {
    return (
      <a
        className="flat-button pull-right close-button"
        onClick={this.props.onClose}
      >
        Close
      </a>
    );
  }

  clearFilter() {
    return (
      <a className="clear-filter pull-right" onClick={this.props.onClear}>
        Clear
      </a>
    );
  }

  render() {
    return (
      <div
        className="footer"
        style={{ display: !this.props.show ? 'none' : null }}
      >
        {this.submitButton()}
        {this.closeButton()}
        {this.clearFilter()}
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default Footer;
