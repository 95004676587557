import React from 'react';
import types from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import Item from './item';

export default class Group extends React.Component {
  static propTypes = {
    group: types.shape({
      children: types.arrayOf(
        types.shape({
          selected: types.bool,
        }),
      ),
      name: types.string,
    }).isRequired,
    toggleItems: types.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showChildren: false,
    };
  }

  componentDidMount() {
    const hasChildrenSelected = this.anyChildrenSelected();
    this.setState({
      showChildren: hasChildrenSelected,
    });
  }
  /**
   * Are all the group's children selected?
   * @returns {boolean}
   */

  allChildrenSelected = () => {
    const {
      group: { children },
    } = this.props;
    return children.every((c) => c.selected);
  };

  anyChildrenSelected = () => {
    const {
      group: { children },
    } = this.props;

    return children.some((child) => child.selected);
  };

  /**
   * Toggle the display of the children
   */
  toggleChildrenDisplay = () => {
    this.setState((prevState) => ({ showChildren: !prevState.showChildren }));
  };

  /**
   * Toggle all childrens' selected state.
   * @param event
   */
  toggleSelectedChildren = (event) => {
    const {
      group: { children },
      toggleItems,
    } = this.props;
    event.preventDefault();
    event.stopPropagation();

    const newValue = !this.allChildrenSelected();

    toggleItems(children, newValue);
  };

  renderChildren = () => {
    const { showChildren } = this.state;
    const {
      group: { children },
      toggleItems,
    } = this.props;

    if (!showChildren) return null;

    return children.map((item, index) => (
      <Item
        className="child"
        item={item}
        toggle={toggleItems}
        key={String(index)}
      />
    ));
  };

  render() {
    const { showChildren } = this.state;
    const { group } = this.props;

    const active = this.anyChildrenSelected();
    const classes = ['grouped-item', 'border-color', 'group-parent'];

    if (showChildren) {
      classes.push('open');
    }

    if (active) {
      classes.push('active');
    }

    return (
      <div className={cx(classes)}>
        <div className="grouped-item-label">
          <div
            role="button"
            tabIndex={0}
            onClick={this.toggleChildrenDisplay}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ')
                this.toggleChildrenDisplay();
            }}
          >
            <i
              className={cx(
                'fa',
                'show-children',
                showChildren ? 'fa-angle-down' : 'fa-angle-right',
              )}
            />
            <span>{group.name}</span>
          </div>
        </div>
        {this.renderChildren()}
      </div>
    );
  }
}
