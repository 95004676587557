import * as React from 'react';

import cx from 'classnames';

export interface ConfirmProps {
  id: string;
  cancelText?: string;
  className?: string;
  confirm_text: string;
  message?: string;
  title: string;
  onCancel?(): void;
  onConfirm?(): void;
  children?: any;
  confirmClass?: string;
}

export default class Confirm extends React.PureComponent<ConfirmProps, {}> {
  static defaultProps = {
    cancelText: 'Cancel',
    confirmClass: 'style-primary',
  };

  onCancel = () => {
    $(document).trigger(`statuspage.confirm.${this.props.id}.canceled`);
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onConfirm = () => {
    $(document).trigger(`statuspage.confirm.${this.props.id}.confirmed`);
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  };

  message = () => {
    if (!this.props.message) {
      return null;
    }

    return (
      <div className="modal-body form-horizontal">
        <p>{this.props.message}</p>
      </div>
    );
  };

  children = () => {
    if (!this.props.children) return null;
    return this.props.children;
  };

  render() {
    const className = cx(
      'modal hide fade modal-confirmation',
      this.props.className,
    );
    return (
      <div
        className={className}
        id={`modal-confirmation-${this.props.id}`}
        style={{ display: 'none' }}
        aria-labelledby={this.props.title}
        role="dialog"
      >
        <div className="modal-content">
          <div className="modal-header">
            <a
              href="#"
              data-dismiss="modal"
              className="close close-adg3-hide"
              id={`modal-confirmation-${this.props.id}-cross`}
            >
              ×
            </a>
            <h4>{this.props.title}</h4>
          </div>

          {this.message()}

          {this.children()}

          <div className="modal-footer">
            <a
              href="#"
              id={`btn-cancel-${this.props.id}`}
              data-dismiss="modal"
              className="close secondary"
              onClick={this.onCancel}
            >
              {this.props.cancelText}
            </a>
            <button
              className={cx('cpt-button', this.props.confirmClass)}
              data-dismiss="modal"
              id={`btn-confirm-${this.props.id}`}
              onClick={this.onConfirm}
            >
              {this.props.confirm_text}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
