interface ColorByNameMap {
  [key: string]: string;
}

export const COLORS_BY_NAME: ColorByNameMap = {
  aliceblue: '#f0f8ff',
  antiquewhite: '#faebd7',
  aqua: '#00ffff',
  aquamarine: '#7fffd4',
  azure: '#f0ffff',
  beige: '#f5f5dc',
  bisque: '#ffe4c4',
  black: '#000000',
  blanchedalmond: '#ffebcd',
  blue: '#0000ff',
  blueviolet: '#8a2be2',
  brown: '#a52a2a',
  burlywood: '#deb887',
  cadetblue: '#5f9ea0',
  chartreuse: '#7fff00',
  chocolate: '#d2691e',
  coral: '#ff7f50',
  cornflowerblue: '#6495ed',
  cornsilk: '#fff8dc',
  crimson: '#dc143c',
  cyan: '#00ffff',
  darkblue: '#00008b',
  darkcyan: '#008b8b',
  darkgoldenrod: '#b8860b',
  darkgray: '#a9a9a9',
  darkgreen: '#006400',
  darkkhaki: '#bdb76b',
  darkmagenta: '#8b008b',
  darkolivegreen: '#556b2f',
  darkorange: '#ff8c00',
  darkorchid: '#9932cc',
  darkred: '#8b0000',
  darksalmon: '#e9967a',
  darkseagreen: '#8fbc8f',
  darkslateblue: '#483d8b',
  darkslategray: '#2f4f4f',
  darkturquoise: '#00ced1',
  darkviolet: '#9400d3',
  deeppink: '#ff1493',
  deepskyblue: '#00bfff',
  dimgray: '#696969',
  dodgerblue: '#1e90ff',
  firebrick: '#b22222',
  floralwhite: '#fffaf0',
  forestgreen: '#228b22',
  fuchsia: '#ff00ff',
  gainsboro: '#dcdcdc',
  ghostwhite: '#f8f8ff',
  gold: '#ffd700',
  goldenrod: '#daa520',
  gray: '#808080',
  green: '#008000',
  greenyellow: '#adff2f',
  honeydew: '#f0fff0',
  hotpink: '#ff69b4',
  'indianred ': '#cd5c5c',
  indigo: '#4b0082',
  ivory: '#fffff0',
  khaki: '#f0e68c',
  lavender: '#e6e6fa',
  lavenderblush: '#fff0f5',
  lawngreen: '#7cfc00',
  lemonchiffon: '#fffacd',
  lightblue: '#add8e6',
  lightcoral: '#f08080',
  lightcyan: '#e0ffff',
  lightgoldenrodyellow: '#fafad2',
  lightgrey: '#d3d3d3',
  lightgreen: '#90ee90',
  lightpink: '#ffb6c1',
  lightsalmon: '#ffa07a',
  lightseagreen: '#20b2aa',
  lightskyblue: '#87cefa',
  lightslategray: '#778899',
  lightsteelblue: '#b0c4de',
  lightyellow: '#ffffe0',
  lime: '#00ff00',
  limegreen: '#32cd32',
  linen: '#faf0e6',
  magenta: '#ff00ff',
  maroon: '#800000',
  mediumaquamarine: '#66cdaa',
  mediumblue: '#0000cd',
  mediumorchid: '#ba55d3',
  mediumpurple: '#9370d8',
  mediumseagreen: '#3cb371',
  mediumslateblue: '#7b68ee',
  mediumspringgreen: '#00fa9a',
  mediumturquoise: '#48d1cc',
  mediumvioletred: '#c71585',
  midnightblue: '#191970',
  mintcream: '#f5fffa',
  mistyrose: '#ffe4e1',
  moccasin: '#ffe4b5',
  navajowhite: '#ffdead',
  navy: '#000080',
  oldlace: '#fdf5e6',
  olive: '#808000',
  olivedrab: '#6b8e23',
  orange: '#ffa500',
  orangered: '#ff4500',
  orchid: '#da70d6',
  palegoldenrod: '#eee8aa',
  palegreen: '#98fb98',
  paleturquoise: '#afeeee',
  palevioletred: '#d87093',
  papayawhip: '#ffefd5',
  peachpuff: '#ffdab9',
  peru: '#cd853f',
  pink: '#ffc0cb',
  plum: '#dda0dd',
  powderblue: '#b0e0e6',
  purple: '#800080',
  red: '#ff0000',
  rosybrown: '#bc8f8f',
  royalblue: '#4169e1',
  saddlebrown: '#8b4513',
  salmon: '#fa8072',
  sandybrown: '#f4a460',
  seagreen: '#2e8b57',
  seashell: '#fff5ee',
  sienna: '#a0522d',
  silver: '#c0c0c0',
  skyblue: '#87ceeb',
  slateblue: '#6a5acd',
  slategray: '#708090',
  snow: '#fffafa',
  springgreen: '#00ff7f',
  steelblue: '#4682b4',
  tan: '#d2b48c',
  teal: '#008080',
  thistle: '#d8bfd8',
  tomato: '#ff6347',
  turquoise: '#40e0d0',
  violet: '#ee82ee',
  wheat: '#f5deb3',
  white: '#ffffff',
  whitesmoke: '#f5f5f5',
  yellow: '#ffff00',
  yellowgreen: '#9acd32',
};

export const HEX_REGEX = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
export const RGB_REGEX = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/i;

type RGBA = {
  r: number;
  g: number;
  b: number;
  a?: number;
};

export default class Color {
  r: number;
  g: number;
  b: number;
  a: number;

  /**
   * Take a hex string, an rgb/a string, color name or an object.
   * validate it and normalize to an rgba object
   * @param {string|object} colorStringOrObject
   * @returns {Color}
   */
  constructor(props: string | Color | RGBA) {
    let color: Color | RGBA;
    if (props instanceof Color) {
      color = props;
    } else if (typeof props === 'string') {
      color =
        Color.parseHex(props) ||
        Color.parseRgb(props) ||
        Color.parseColorName(props);
    } else {
      color = props;
    }

    this.r = color.r;
    this.g = color.g;
    this.b = color.b;
    this.a = color.a || 1;
  }

  /**
   * Normalize 3 and 6 digit hex color strings
   * @param {String} hex string with single or double digit components
   * @returns String
   */
  static normalizeHexString(hex: string): string {
    let newHex = '';
    if ((hex.indexOf('#') === 0 && hex.length === 4) || hex.length === 3) {
      for (let i = 0; i < hex.length; i += 1) {
        if (hex[i] !== '#') newHex += hex[i] + hex[i];
      }
    } else {
      newHex = hex;
    }
    return newHex;
  }

  /**
   * Convert valid 6 or 3 character hex (less the hash) to a Color instance
   * Since we're converting from hex, alpha will always be 1.
   * @param {string} hex
   * @param {float} [opacity=1]
   * @returns {Color}
   */
  static parseHex(hex: string, opacity: number = 1): Color | null {
    const result = HEX_REGEX.exec(Color.normalizeHexString(hex));
    if (result === null) return null;

    return new Color({
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: opacity,
    });
  }

  /**
   * Convert a valid rgb/a string to a Color instance.
   * @param {string} string - rgb(1, 2, 3), rgba(1, 2, 3, 0.4), etc.
   * @returns {Color}
   */
  static parseRgb(value: string): Color | null {
    const result = RGB_REGEX.exec(value);
    if (result === null) return null;

    return new Color({
      r: parseInt(result[1], 10),
      g: parseInt(result[2], 10),
      b: parseInt(result[3], 10),
      a: result[4] ? parseInt(result[4], 10) : 1,
    });
  }

  /**
   * Is a given hex color string valid?
   * @param {String} hex string
   * @return {Boolean}
   */
  static hexIsValid(hex: string): boolean {
    const pattern = HEX_REGEX;
    return pattern.test(Color.normalizeHexString(hex));
  }

  /**
   * Parse a color name and return a Color instance
   * @param {String} name
   * @return {Color}
   */
  static parseColorName(name: string): Color {
    if (typeof COLORS_BY_NAME[name.toLowerCase()] === 'undefined') {
      throw new Error('could not parse input');
    }
    return new Color(COLORS_BY_NAME[name.toLowerCase()]);
  }

  static componentToHex(value: number): string {
    const hex = value.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }

  toHex(): string {
    return `#${Color.componentToHex(this.r)}${Color.componentToHex(
      this.g,
    )}${Color.componentToHex(this.b)}`.toUpperCase();
  }

  get hex(): string {
    return this.toHex();
  }

  toRgb(): string {
    return `rgb(${[this.r, this.g, this.b].join(', ')})`;
  }

  get rgb(): string {
    return this.toRgb();
  }

  toRgba(): string {
    return `rgba(${[this.r, this.g, this.b, this.a].join(', ')})`;
  }

  get rgba(): string {
    return this.toRgba();
  }

  /**
   * Lightens the current color value by x%
   * @param {Number} value
   * @returns {Color}
   */
  lighten(value: number): Color {
    this.r =
      Math.ceil(this.r * value + this.r) <= 255
        ? Math.ceil(this.r * value + this.r)
        : 255;
    this.g =
      Math.ceil(this.g * value + this.g) <= 255
        ? Math.ceil(this.g * value + this.g)
        : 255;
    this.b =
      Math.ceil(this.b * value + this.b) <= 255
        ? Math.ceil(this.b * value + this.b)
        : 255;
    return this;
  }

  /**
   * Darkens the current color value by x%
   * @param {Number} value
   * @returns {Color}
   */
  darken(value: number): Color {
    this.r =
      Math.floor(this.r - this.r * value) >= 0
        ? Math.floor(this.r - this.r * value)
        : 255;
    this.g =
      Math.floor(this.g - this.g * value) >= 0
        ? Math.floor(this.g - this.g * value)
        : 255;
    this.b =
      Math.floor(this.b - this.b * value) >= 0
        ? Math.floor(this.b - this.b * value)
        : 255;
    return this;
  }
}
