import * as React from 'react';
import * as ReactDOM from 'react-dom';
import SentryReporter from './sentry_reporter';

interface ComponentList {
  [name: string]: any;
}

const KnownComponents = {};

export default function componentLoader(components: ComponentList): void {
  Object.assign(KnownComponents, components);

  // When we invoke the `react_component` helper in ERB files we actually render
  // placeholder elements with the `data-react-class` attribute set to the
  // helper's first argument and `data-react-props` properties set to a
  // serialized JSON object of the helper's second argument. We then need to
  // manipulate the DOM at runtime to render actual React elements into these
  // placeholders.

  // Find all the React element placeholder nodes coming from the
  // `react_component` ERB helper. We're going to iterate over these and
  // perform the renders we need ad-hoc.
  const reactComponentNodes = document.querySelectorAll('*[data-react-class]');
  const reactComponents = Array.from(reactComponentNodes);

  reactComponents.forEach((element) => {
    const className = element.getAttribute('data-react-class');

    if (className === null || components[className] == null) {
      return;
    }

    let Component = components[className];
    Component = Component.default ? Component.default : Component;

    const Listener$rerender = () => {
      // We could probably be pulling from the `element.dataset` value, but this
      // was in here already probably from when we needed to support older
      // versions of IE. Leaving it for now.
      //
      // We just need to keep in mind that instead of updating the `dataset`
      // value (which is initialized from the `data-*` attributes), we'd have to
      // fall back to the non-standard way of updating the attribute with prop
      // changes.
      const props = JSON.parse(
        element.getAttribute('data-react-props') || '{}',
      );

      // ReactDOM caches the render that sets underneath `element`. In order to
      // re-render externally we have to first clear out the previous render
      // result. Note that we shouldn't do this with native DOM node removal
      // because that will not cleanup the ReactDOM references to the element.
      // This call is safe (will not throw) if the target `element` is empty.
      ReactDOM.unmountComponentAtNode(element);

      // Render the specified component into the target `element` placeholder.
      ReactDOM.render(
        <SentryReporter>
          <Component {...props} />
        </SentryReporter>,
        element,
      );
    };

    // This is a bit (a lot) of hackery to work around TypeScript not playing
    // nicely with the `dataset` attribute on `element`. It basically means
    // instead of invoking:
    //
    //   el.dataset.render()
    //
    // We're stuck with:
    //
    //   el.dispatchEvent(new Event('rerender'));
    //
    // Not the worst thing in the world, but less than ideal. You also don't
    // (and probably shouldn't) want to use this anyway unless you really,
    // really, really need to... and even then you should question yourself.
    element.addEventListener('rerender', Listener$rerender);

    // Invoke the rerender listener to complete the first `ReactDOM` render into
    // the placeholder element.
    Listener$rerender();
  });
}

export function loadKnownComponents(): void {
  componentLoader(KnownComponents);
}

Object.assign(window, { loadKnownComponents });
