import React from 'react';
import PropTypes from 'prop-types';

export const optional = required => {
  if (required === false) {
    return <small>(optional)</small>;
  }
  return null;
};

/**
 * Input label
 * @deprecated
 * @returns {*}
 */
export const label = function() {
  if (this.props.label) {
    return (
      <label>
        {this.props.label} {optional(this.props.required)}
      </label>
    );
  }
  return null;
};

export const Label = ({ text, name, required }) => {
  if (text) {
    return (
      <label htmlFor={name}>
        {name} {optional(required)}
      </label>
    );
  }
  return null;
};

Label.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
};

/**
 * Help block text
 * @returns {*}
 */
export const helpBlock = function() {
  if (this.props.helpBlockText) {
    return <div className="help-block">{this.props.helpBlockText}</div>;
  }
  return null;
};

export const HelpBlock = ({ text }) => {
  if (text) {
    return <div className="help-block">{text}</div>;
  }
  return null;
};

HelpBlock.propTypes = {
  text: PropTypes.string,
};

HelpBlock.defaultProps = {
  text: null,
};
