import React from 'react';
import types from 'prop-types';
import cx from 'classnames';

import Item from './item';
import Group from './group';

const GroupedItemsCollection = ({
  hasGroupedItems,
  items,
  showTooltipDescriptions,
  toggleItems,
}) => (
  <div className="body">
    <div className={cx('grouped-items', hasGroupedItems ? null : 'no-groups')}>
      {items.map((item, index) =>
        item.group ? (
          <Group
            group={item}
            toggleItems={toggleItems}
            key={String(index)}
            showChildren={item.showChildren}
            showTooltipDescriptions={showTooltipDescriptions}
          />
        ) : (
          <Item
            item={item}
            toggle={toggleItems}
            key={String(index)}
            showTooltipDescription={showTooltipDescriptions}
          />
        ),
      )}
    </div>
  </div>
);

GroupedItemsCollection.propTypes = {
  hasGroupedItems: types.bool,
  items: types.arrayOf(
    types.shape({
      showChildren: types.bool,
      group: types.bool,
    }),
  ).isRequired,
  showTooltipDescriptions: types.bool,
  toggleItems: types.func.isRequired,
};
GroupedItemsCollection.defaultProps = {
  hasGroupedItems: false,
  showTooltipDescriptions: false,
};

export default GroupedItemsCollection;
