/* README:
 * Removed  "import * as $ from 'jquery'" since the invocation of tooltip was breaking on it.
 * We rely on a global imported jQuery, which has the tooltip method,
 * to get our tests to consistently work for now.
 * TODO: [ ] Componentize jQuery and tooltips together.
 * */

import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface TooltipProps {
  title: string;
  placement: 'top' | 'right' | 'bottom' | 'left';
  animationDuration: number;
  delay: number;
  maxWidth: number;
  theme: string | string[];
  disableOnTouchScreen: boolean;
  className?: string;
  contentAsHtml?: boolean;
  iconVisible: boolean;
}

export default class Tooltip extends React.PureComponent<TooltipProps> {
  static defaultProps = {
    animationDuration: 100,
    placement: 'top',
    delay: 100,
    maxWidth: 240,
    theme: 'tooltipster-borderless',
    disableOnTouchScreen: true,
    iconVisible: true,
  };

  state = {
    iconVisible: this.props.iconVisible,
  };

  static getDerivedStateFromProps(
    nextProps: { iconVisible: boolean },
    prevState: { iconVisible: boolean },
  ) {
    if (nextProps.iconVisible !== prevState.iconVisible) {
      return { iconVisible: nextProps.iconVisible };
    }
    return null;
  }

  componentDidMount() {
    this.mountTooltip();
  }

  componentDidUpdate() {
    this.fillTooltip();
  }

  componentWillUnmount() {
    this.destroyTooltip();
  }

  mountTooltip() {
    if (
      'ontouchstart' in document.documentElement! &&
      this.props.disableOnTouchScreen
    ) {
      return;
    }
    const domNode = ReactDOM.findDOMNode(this) as Element;
    $(domNode).tooltipster({
      animationDuration: this.props.animationDuration,
      side: this.props.placement,
      content: this.props.title,
      delay: this.props.delay,
      maxWidth: this.props.maxWidth,
      theme: this.props.theme,
      contentAsHTML: !!this.props.contentAsHtml,
    });

    domNode.addEventListener('focus', () => $(domNode).tooltipster('open'));
    domNode.addEventListener('blur', () => $(domNode).tooltipster('close'));
  }

  fillTooltip() {
    if (
      'ontouchstart' in document.documentElement! &&
      this.props.disableOnTouchScreen
    ) {
      return;
    }
    const domNode = ReactDOM.findDOMNode(this) as Element;
    $(domNode).tooltipster('content', this.props.title);
  }

  destroyTooltip() {
    if (
      'ontouchstart' in document.documentElement! &&
      this.props.disableOnTouchScreen
    ) {
      return;
    }
    const domNode = ReactDOM.findDOMNode(this) as Element;

    domNode.removeEventListener('focus', () => $(domNode).tooltipster('open'));
    domNode.removeEventListener('blur', () => $(domNode).tooltipster('close'));

    $(domNode).tooltipster('destroy');
  }

  render() {
    return (
      <span
        className={this.props.className}
        tabIndex={this.state.iconVisible ? 0 : -1}
      >
        {this.props.children}
      </span>
    );
  }
}
