import React from 'react';

class Header extends React.Component {
  title() {
    if (this.props.title && this.props.title.length > 0) {
      return <span className="title font-large">{this.props.title}</span>;
    }
  }

  search() {
    if (this.props.searchable) {
      return (
        <input
          className="pull-right border-color"
          type="text"
          name="search"
          id="filter-filter"
          placeholder="search by name"
          onChange={this.props.onChange}
        />
      );
    }
  }

  render() {
    return (
      <div
        className="header border-color"
        style={{ display: !this.props.show ? 'none' : null }}
      >
        {this.title()}
        {this.search()}
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default Header;
