window.StripeCheckout = window.StripeCheckout || {};

class AttributeError extends Error {}

let scriptLoaded = false;

class UpdatePaymentMethod {
  /**
   * Stripe Checkout wrapper
   * @param options
   * @param options.publicStripeKey {String} publishable stripe key
   */
  constructor(options) {
    this._checkRequiredAttributes(options, ['publicStripeKey']);

    this.publicStripeKey = options.publicStripeKey;
    this.locale = options.locale || 'auto';

    $.getScript('https://checkout.stripe.com/checkout.js').done(
      this._onScriptLoaded.bind(this),
    );
  }

  /**
   * Method gets called when a token is successfully returned from Stripe.
   * @required
   */
  onSuccess() {
    throw new Error('Abstract method error.  You must define #onSuccess');
  }

  /**
   * Opens the card form modal.
   * @param options
   * @param options.title {String} Modal title
   * @param options.email {String} Email address of submitter
   * @param options.buttonText {String} Text of submit button
   */
  open(options) {
    if (!scriptLoaded) return;

    this._checkRequiredAttributes(options, ['title', 'email', 'buttonText']);

    this.handler.open({
      name: options.title,
      email: options.email,
      allowRememberMe: options.allowRememberMe || false,
      zipCode: options.zipCode || true,
      panelLabel: options.buttonText,
    });
  }

  _onScriptLoaded() {
    scriptLoaded = true;
    this._initializeHandler();
    this._setCloseHandler();
  }

  _initializeHandler() {
    this.handler = StripeCheckout.configure({
      key: this.publicStripeKey,
      locale: this.locale,
      token: this.onSuccess,
    });
  }

  _setCloseHandler() {
    $(window).on('popstate', this.handler.close.bind(this));
  }

  _unSetCloseHandler() {
    $(window).off('popstate', this.handler.close.bind(this));
  }

  _checkRequiredAttributes(options, required) {
    required.map(requiredOption => {
      if (!~Object.keys(options).indexOf(requiredOption))
        throw new AttributeError(`Required option: ${requiredOption}`);
    });
  }
}

export default UpdatePaymentMethod;
