import request from 'lib/request';

class AbstractMethodError extends Error {}

class Base {
  /**
   * Fetch object by id
   * @param id
   * @returns {Promise}
   */
  static fetch(id) {}

  constructor(attributes) {
    this.routes = attributes.routes;
    delete attributes.routes;
    this.request = request;
    this._setAttributes(attributes);
  }

  /**
   * Saves a new or existing record depending on if we have an id
   * @returns {Promise}
   */
  save() {
    this._ensureRoutes();
    let data = {};
    data[this.constructor.name.toLowerCase()] = this._serializableObject();

    if (this.id) {
      // patch the record
      return this.request.patch({
        url: this.routes.show(this.id),
        data: data,
      });
    } else {
      // post new record
      return this.request.post({
        url: this.routes.index,
        data: data,
      });
    }
  }

  update(attributes) {
    this._setAttributes(attributes);
    return this.save();
  }

  delete() {
    this._ensureRoutes();
    if (!this.id) throw new Error('This object is not persisted.');

    let data = {};
    data[`${this.constructor.name.toLowerCase()}_id`] = this.id;

    return this.request.delete({
      url: this.routes.show(data),
    });
  }

  _setAttributes(attributes) {
    for (let key in attributes) {
      this[key] = attributes[key];
    }
  }

  _serializableObject() {
    throw new AbstractMethodError();
  }

  _ensureRoutes() {
    if (!this.routes)
      throw new Error('You must provide a routes object in your constructor.');
  }
}
Base.api_host = window.location.hostname.replace('manage', 'api');
Base.api_port = window.location.port;
Base.api_protocol = window.location.protocol;

export default Base;
