import { capitalize } from 'lodash';

export function titleize(s: string) {
  return s
    .split('_')
    .map(capitalize)
    .join(' ');
}

function normalizeTime(input: string | Date | null | undefined): number {
  if (input instanceof Date) {
    return input.getTime();
  }
  if (typeof input === 'string') {
    return new Date(input).getTime();
  }
  return new Date().getTime();
}

export function pluralize(word: string, count: number, irregular?: string) {
  if (Math.abs(count) === 1) {
    // because Oscar Deits likes to pluralize negative objects
    return word;
  }
  if (irregular) {
    return irregular;
  }
  return `${word}s`;
}

export function relativeTimestamp(
  input_ts: string | Date,
  from_ts?: string | Date | null,
) {
  // find the ms values of the given anchor time OR current time and timestamp
  const anchor = normalizeTime(from_ts);

  // find the input time we are converting to a relative human string
  const ts = normalizeTime(input_ts);

  // determine the delta, round to the nearest minute
  const minDiff = Math.floor((anchor - ts) / 1000 / 60);

  // display delta in minutes, seconds, days, or years ago
  if (minDiff < 1) {
    return '< 1 MIN AGO';
  }
  // Less than 1 hour ago
  if (minDiff < 60) {
    const noun = pluralize('min', minDiff).toUpperCase();
    return `${minDiff} ${noun} AGO`;
  }
  // Less than 1 day ago
  if (minDiff < 1440) {
    const hourDiff = Math.floor(minDiff / 60);
    const noun = pluralize('hour', hourDiff).toUpperCase();
    return `${hourDiff} ${noun} AGO`;
  }
  // Less than 1 year ago
  if (minDiff < 525600) {
    const dayDiff = Math.floor(minDiff / 1440);
    const noun = pluralize('day', dayDiff).toUpperCase();
    return `${dayDiff} ${noun} AGO`;
  }

  const yearDiff = Math.floor(minDiff / 525600);
  const noun = pluralize('year', yearDiff).toUpperCase();
  return `${yearDiff} ${noun} AGO`;
}

export function hasKey<O>(
  obj: O,
  key: string | number | symbol,
): key is keyof O {
  return key in obj;
}
