import Color from 'components/color';

type HexColorString = string;

export const DEFAULT_COLORS = {
  blue: '#3498DB',
  body_background: '#FFFFFF',
  border: '#E0E0E0',
  font: '#333333',
  graph: '#3498DB',
  green: '#2FCC66',
  light_font: '#AAAAAA',
  link: '#3498DB',
  orange: '#E67E22',
  red: '#E74C3C',
  yellow: '#F1C40F',
  no_data: '#B3BAC5',
};

export interface PageColorsArguments {
  blue: HexColorString;
  body_background: HexColorString;
  border: HexColorString;
  font: HexColorString;
  graph: HexColorString;
  green: HexColorString;
  light_font: HexColorString;
  link: HexColorString;
  orange: HexColorString;
  red: HexColorString;
  yellow: HexColorString;
  no_data: HexColorString;
}

export default class PageColors {
  blue: Color;
  bodyBackground: Color;
  border: Color;
  font: Color;
  graph: Color;
  green: Color;
  lightFont: Color;
  link: Color;
  orange: Color;
  red: Color;
  yellow: Color;
  no_data: Color;

  constructor(pageColors: PageColorsArguments = DEFAULT_COLORS) {
    const colorArgs = Object.assign({}, DEFAULT_COLORS, pageColors);
    this.blue = new Color(colorArgs.blue);
    this.bodyBackground = new Color(colorArgs.body_background);
    this.border = new Color(colorArgs.border);
    this.font = new Color(colorArgs.font);
    this.graph = new Color(colorArgs.graph);
    this.green = new Color(colorArgs.green);
    this.lightFont = new Color(colorArgs.light_font);
    this.link = new Color(colorArgs.link);
    this.orange = new Color(colorArgs.orange);
    this.red = new Color(colorArgs.red);
    this.yellow = new Color(colorArgs.yellow);
    this.no_data = new Color(colorArgs.no_data);
  }

  get operational(): Color {
    return this.green;
  }

  get underMaintenance(): Color {
    return this.blue;
  }

  get partiallyDegraded(): Color {
    return this.yellow;
  }

  get minorOutage(): Color {
    return this.orange;
  }

  get majorOutage(): Color {
    return this.red;
  }
}
