import { PureComponent } from 'react';

declare global {
  interface Window {
    Raven: any;
    ContainerAPI: any;
  }
}

interface SentryReportingProps {
  children: any;
}

export function reportError(error: any, errorInfo: any) {
  if (window.Raven && typeof window.Raven.captureException === 'function') {
    window.Raven.captureException(error, errorInfo);
  }
}

export default class SentryReporter extends PureComponent<
  SentryReportingProps
> {
  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error,
    });

    if (
      process.env.RAILS_ENV === 'development' ||
      process.env.RAILS_ENV === 'test' ||
      window.location.hostname === 'manage.statuspagetest.com'
    ) {
      console.warn('Caught error at react boundary:');
      console.warn(error);
      console.warn('at stack location', error.stack);
    }

    reportError(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}
