import cx from 'classnames';

export enum Method {
  SHOW = 'show',
  DEFERRED = 'deferred',
}

export enum Color {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface NotifyProps {
  message: string;
  id: string;
  delay: number;
  color: Color;
  autoHide: boolean;
  method: Method;
}

class Notify {
  private props: NotifyProps;
  private el: HTMLDivElement = document.createElement('div');

  constructor(props: NotifyProps) {
    this.props = props;
    switch (this.props.method) {
      case Method.DEFERRED:
        this.deferred();
        return;
      case Method.SHOW:
        this.show();
        return;
    }
  }

  show = () => {
    this.el.className = cx(
      'cpt-notification',
      'show',
      'showing',
      'flag-message',
      this.props.color,
    );
    const message = document.createElement('div');
    message.classList.add('cpt-notification-message');
    message.innerText = this.props.message;
    this.el.appendChild(message);
    const container = document.getElementById('cpt-notification-container');
    if (container) {
      container.appendChild(this.el);
    } else {
      document.body.appendChild(this.el);
    }
    if (this.props.autoHide) {
      setTimeout(this.hide, this.props.delay);
    }
    setTimeout(() => this.el.classList.remove('showing'), 500);
  };

  hide = () => {
    try {
      this.el.classList.add('hiding');
      setTimeout(this.remove, 500);
    } catch (e) {
      // Doesn't matter if we fail to remove the node -- if it's not there, the message is already hidden.
    }
  };

  remove = () => {
    try {
      const container = document.getElementById('cpt-notification-container');
      if (container) {
        container.removeChild(this.el);
      } else {
        document.body.removeChild(this.el);
      }
    } catch (e) {
      // Doesn't matter if we fail to remove the node -- if it's not there, the message is already hidden.
    }
  };

  // This function just sets the local storage for the 'old' jQuery code to render.  We don't handle it.
  deferred = () => {
    localStorage.setItem('HRBnotify', this.props.message);
    localStorage.setItem('HRBnotifyclass', this.props.color);
    return null;
  };
}

export default function notify(message: string, opts: Partial<NotifyProps>) {
  const defaults: NotifyProps = {
    message,
    id: `notify-${Math.ceil(Math.random() * 1000000)}`,
    delay: 5000,
    color: Color.SUCCESS,
    method: Method.SHOW,
    autoHide: true,
    ...opts,
  };

  // Only applicable when renders inside SPA via iframe
  if ((window as any).__SPA_NOTIFICATION__) {
    return HRB.utils.notify(message, {
      cssClass: defaults.color,
      autoHide: defaults.autoHide,
    });
  }

  return new Notify(defaults);
}
