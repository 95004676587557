import React from 'react';
import PropTypes from 'prop-types';
import Select from '@atlaskit/select';
import { Field } from '@atlaskit/form';
import _ from 'lodash';

class SelectInput extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    ariaLabel: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    isSearchable: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ).isRequired,
    styles: PropTypes.object,
    modal: PropTypes.bool,
  };

  static defaultProps = {
    isSearchable: false,
    modal: false,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.state.options = this.formatOptions(props.options);
    this.state.selected = this.getSelectedOption(
      props.defaultValue,
      this.state.options,
    );

    // Find original element that works with jquery based js hooks
    // And send change events to the element for back compatibility reason
    if (this.props.jshook) {
      this.jshook = document.getElementsByClassName(this.props.jshook)[0];
      // Synchronize state of original select if it changes by js hooks
      this.jshook &&
        this.jshook.addEventListener('change', this.onChangeFromJShook);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.options === this.props.options &&
      nextProps.defaultValue === this.props.defaultValue
    )
      return;

    const nextState = {
      options: this.formatOptions(nextProps.options),
    };
    // Only change the selected value if defaultValue is set to something, or the current selected option is not in the new options
    if (
      nextProps.defaultValue ||
      !nextState.options.find(
        (option) => option.value === this.state.selected.value,
      )
    ) {
      nextState.selected = this.getSelectedOption(
        nextProps.defaultValue,
        nextState.options,
      );
    }

    this.setState(nextState);
  }

  formatOptions(options) {
    if (options && options.map) {
      if (options[0].group) {
        const groupedOptions = _.groupBy(options, 'group');
        const formattedOptions = Object.keys(groupedOptions).map((key) => ({
          label: key,
          options: groupedOptions[key].map((option) => ({
            label: option.name,
            value: option.value,
          })),
        }));
        return formattedOptions;
      }
      return options.map((option) => ({
        label: option.name,
        value: option.value,
      }));
    }
    return [];
  }

  getSelectedOption = (value, options) => {
    return (
      options.find((option) => option && option.value === value) ||
      (options && options[0].options
        ? _.flatten(options.map((option) => option.options)).find(
            (option) => option && option.value === value,
          ) || options[0].options[0]
        : options[0])
    );
  };

  onChangeFromJShook = (event) => {
    this.setState({
      selected: this.getSelectedOption(
        event.currentTarget.value,
        this.state.options,
      ),
    });
  };

  onChange = (selected) => {
    this.setState({ selected });
    this.props.onChange &&
      this.props.onChange({
        target: { value: selected.value, name: this.props.name },
      });
    // proxy event to the js hook element to trigger further changes
    if (this.jshook) {
      this.jshook.value = selected.value;
      this.jshook.dispatchEvent(new Event('change'));
    }
  };

  render() {
    return (
      <div className="control-group">
        <Field name={this.props.name} label={this.props.label}>
          {() => (
            <Select
              ref={this.element}
              id={this.props.id}
              name={this.props.name}
              placeholder={this.props.placeholder}
              isDisabled={this.props.disabled}
              options={this.state.options}
              value={this.state.selected}
              onChange={this.onChange}
              isSearchable={this.props.isSearchable}
              classNamePrefix="select-input"
              menuPortalTarget={document.body}
              aria-label={this.props.ariaLabel}
              styles={{
                ...this.props.styles,
                menuPortal: (base) => ({ ...base, zIndex: 1050 }),
              }}
            />
          )}
        </Field>
      </div>
    );
  }
}

export default SelectInput;
