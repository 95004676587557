import _ from 'lodash';
import Base from 'resources/base';
import querystring from 'querystring';
import urllib from 'url';

const ApiRoutes = {};

/**
 * For every route in ROUTES_BY_NAME, ApiRoutes will have two methods, `<ROUTE NAME>_url` and `<ROUTE NAME>_path`.
 * Each will take parameters. If a parameter matches a route parameter, the route parameter is substituted for the
 * parameter value. The remaining parameters are serialized to a query string. Not providing the route parameters
 * will result in a runtime error.
 *
 * Example:
 * The route "resource_subchild_action": "/resource/:resourceId/subchild/:subchildId/action" will generate two
 * methods on ApiRoutes, `resource_subchild_action_url` and `resource_subchild_action_path`. Therefore, calling
 * `resource_subchild_action_path({resourceId: 'foo', subchildId: 'bar', search: 'query'})` would return the string
 * `"/resource/foo/subchild/bar/action?search=query"`, and
 * `resource_subchild_action_path({resourceId: 'foo', search: 'query'})` would throw a runtime error.
 */
const ROUTES_BY_NAME = {
  page_incidents: '/v1/pages/:pageCode/incidents',
  page_subscribers: '/v1/pages/:pageCode/subscribers.json',
  page_subscribers_count: '/v1/pages/:pageCode/subscribers/count.json',
  page_subscribers_histogram_by_state:
    '/v1/pages/:pageCode/subscribers/histogram_by_state.json',
  page_subscribers_reactivate: '/v1/pages/:pageCode/subscribers/reactivate',
  page_subscribers_unsubscribe: '/v1/pages/:pageCode/subscribers/unsubscribe',
  page_subscribers_resend_confirmation:
    '/v1/pages/:pageCode/subscribers/resend_confirmation',
  page_subscriber_resend_confirmation:
    '/v1/pages/:pageCode/subscribers/:subscriberCode/resend_confirmation',
};

/**
 * Consumes `params` to fill in route parameters.
 *
 * @param {Object} params The parameters to consume. Consumed parameters are removed from this object.
 * @param {String} path The route path to rewrite.
 * @returns {String} The rewritten path
 * @throws {Error} If a route parameter is not provided in `params`.
 */
const substituteParamsInPath = (params, path) => {
  const routeParamRegex = /:(\w+)/;
  let match = routeParamRegex.exec(path);
  let newPath = path;
  while (match != null) {
    const fullMatch = match[0];
    const paramName = match[1];
    if (params && Object.prototype.hasOwnProperty.call(params, paramName)) {
      newPath = newPath.replace(
        fullMatch,
        encodeURIComponent(params[paramName]),
      );
      delete params[paramName];
    } else {
      throw new Error(`You must provide a ${paramName}`);
    }

    match = routeParamRegex.exec(newPath);
  }
  return newPath;
};

Object.getOwnPropertyNames(ROUTES_BY_NAME).forEach(routeName => {
  ApiRoutes[`${routeName}_path`] = params => {
    const newParams = _.clone(params);
    const newPath = substituteParamsInPath(
      newParams,
      ROUTES_BY_NAME[routeName],
    );
    if (Object.keys(newParams).length) {
      return `${newPath}?${querystring.encode(newParams)}`;
    }
    return newPath;
  };

  ApiRoutes[`${routeName}_url`] = params => {
    const newParams = _.clone(params);
    const newPath = substituteParamsInPath(
      newParams,
      ROUTES_BY_NAME[routeName],
    );
    return urllib.format({
      protocol: Base.api_protocol,
      hostname: Base.api_host,
      port: Base.api_port,
      pathname: newPath,
      query: newParams,
    });
  };
});

export default ApiRoutes;
