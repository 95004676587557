import Base from 'resources/base';
import request from 'lib/request';
import ApiRoutes from 'lib/api_routes';
import urllib from 'url';

export default class Subscriber extends Base {
  constructor(attributes) {
    attributes.routes = {
      show: options =>
        Routes.page_subscribers_url.bind(null, {
          subscriber_id: options.id,
          page_id: attributes.item_id,
          host: Base.api_host,
          port: Base.api_port,
        }),
    };

    super(attributes);
  }

  static searchRoute(options) {
    if (!options.page_id) throw new Error('You must provide a page_id');
    return ApiRoutes.page_subscribers_url({ pageCode: options.page_id });
  }

  static showRoute(pageCode, subscriberCode) {
    if (!pageCode) throw new Error('You must provide a pageCode');
    const url = urllib.parse(
      Routes.page_subscriber_url(pageCode, { id: subscriberCode }),
    );
    url.search = null;
    url.query = null;
    return urllib.format(url);
  }

  static search(queryOptions) {
    let url;
    if (queryOptions.url) {
      url = queryOptions.url;
    } else {
      url = ApiRoutes.page_subscribers_url({ pageCode: queryOptions.page_id });
    }

    return request
      .get({
        url,
        data: { q: queryOptions.query, sort_field: 'relevance' },
      })
      .then(response => {
        const results = response;
        return results.map(result => {
          const subscriber = new Subscriber(result);
          subscriber.highlights = result.highlight;
          return subscriber;
        });
      });
  }

  static getPage(params) {
    return request.get({ url: ApiRoutes.page_subscribers_url(params) });
  }

  static getCount(params) {
    return request.get({ url: ApiRoutes.page_subscribers_count_url(params) });
  }

  static getHistogramByState(params) {
    return request.get({
      url: ApiRoutes.page_subscribers_histogram_by_state_url(params),
    });
  }

  static unsubscribeSubscribers(pageCode, jsonBody) {
    return request.post({
      url: ApiRoutes.page_subscribers_unsubscribe_url({ pageCode }),
      data: JSON.stringify(jsonBody),
      dataType: 'json',
      contentType: 'application/json',
    });
  }

  static reactivateSubscribers(pageCode, jsonBody) {
    return request.post({
      url: ApiRoutes.page_subscribers_reactivate_url({ pageCode }),
      data: JSON.stringify(jsonBody),
      dataType: 'json',
      contentType: 'application/json',
    });
  }

  static resendConfirmationSubscribers(pageCode, subscriberCodes) {
    const jsonBody = { subscribers: subscriberCodes };
    return request.post({
      url: ApiRoutes.page_subscribers_resend_confirmation_url({ pageCode }),
      data: JSON.stringify(jsonBody),
      dataType: 'json',
      contentType: 'application/json',
    });
  }

  static resendConfirmation(pageCode, subscriberCode) {
    return request.post({
      url: ApiRoutes.page_subscriber_resend_confirmation_url({
        pageCode,
        subscriberCode,
      }),
    });
  }

  _serializableObject() {
    return {
      email: this.email,
      phone_number: this.phone_number,
      phone_display: this.phone_display,
      display_phone_number: this.display_phone_number,
      endpoint: this.endpoint,
      created_at: this.created_at,
      updated_at: this.updated_at,
    };
  }
}
