import React from 'react';
import classnames from 'classnames';
import { label, helpBlock } from './input_commons';

export default class Input extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = 'Input';
  }

  render() {
    return (
      <div
        className={classnames(['control-group', this.props.className])}
        style={this.props.style}
      >
        {label.call(this)}
        <div className="controls" style={this.props.controlsStyle}>
          <input
            className={this.props.inputClass}
            id={this.props.id}
            type={this.props.type || 'text'}
            value={this.props.value}
            defaultValue={this.props.defaultValue}
            onChange={this.props.onChange}
            name={this.props.name}
            placeholder={this.props.placeholder}
            autoFocus={this.props.autoFocus}
          />
          {helpBlock.call(this)}
        </div>
      </div>
    );
  }
}
