import { default as notify, Color } from 'utils/notify';
import { ComponentUptimeEditorStore } from './types';

import { default as bowser } from 'bowser';

// Fire off the save changes function in the store, and notify based on the result
export async function saveChanges(store: ComponentUptimeEditorStore) {
  const { success, message } = await store.ui.saveChanges();
  if (message) {
    notify(message, { color: success ? Color.SUCCESS : Color.ERROR });
  }
  if (success) store.ui.exitEditMode();
}

// Discards any changes made and exits edit mode
export function discardChanges(store: ComponentUptimeEditorStore) {
  store.ui.discardChanges();
  store.ui.exitEditMode();
}

// Returns number of hours for a given seconds value, rounded down.
export function hoursFromSeconds(s: number) {
  return Math.floor(s / 3600);
}

// Returns the number of minutes for a given seconds value, with the result being < 60
export function minutesFromSeconds(s: number) {
  // If less than a minute, round up to 1 minute to show that some outage existed
  if (s > 0 && s < 60) {
    return 1;
  }

  // Otherwise use floor
  return Math.floor((s % 3600) / 60);
}

export function hoursAndMinutesFromSeconds(s: number) {
  return {
    hours: hoursFromSeconds(s),
    minutes: minutesFromSeconds(s),
  };
}

// Convert hours and minutes to seconds
export function convertToSeconds(hours: number, minutes: number): number {
  return hours * 3600 + minutes * 60;
}

// Performs the rounding operations on a seconds value to match the total amount of
// time shown when a seconds value is converted with rounding to hours and minutes.
// This should be used any time a seconds value is checked against the amount of
// time in the input fields.
export function secondsWithRounding(s: number): number {
  const h = hoursFromSeconds(s);
  const m = minutesFromSeconds(s);
  return convertToSeconds(h, m);
}

export function shouldExpandGraphic(): boolean {
  const bps = breakpoints();

  const expandedFor60 =
    window.innerWidth > bps.narrow60 && window.innerWidth <= bps.expanded60;
  const expandedFor30 =
    window.innerWidth > bps.narrow30 && window.innerWidth <= bps.expanded30;
  return expandedFor60 || expandedFor30;
}

// Detects if the user's device is mobile or tablet
export const touchDevice = () => {
  const parser = bowser.getParser(window.navigator.userAgent);
  return parser.getResult().platform.type !== 'desktop'; // if type is either mobile or tablet, return true
};

export function breakpoints() {
  // these match up to the breakpoints defined in constants.scss
  // and should be updated if those are ever modified
  return {
    expanded60: 1300, // = "xl"
    narrow60: 1075, // = "lg"
    expanded30: 748, // = "sm"
    narrow30: 485, // = "xxs"
  };
}
