import * as React from 'react';
import cx from 'classnames';

import { IncidentComponentStatus } from 'containers/incident/types';
import { StatusSelectValues } from './StatusSelectValues';

import Select from '@atlaskit/select';
import { Field } from '@atlaskit/form';
import { Checkbox } from '@atlaskit/checkbox';

const styles = require('./styles.scss');

export interface Props {
  status: IncidentComponentStatus;
  newStatus?: IncidentComponentStatus;
  isOverridden: boolean;
  isBasedOn: boolean;
  basedOnStatus?: IncidentComponentStatus;
  visible: boolean;
  onSelected?: (new_status: IncidentComponentStatus) => void;
  setOverride?: (override: boolean) => void;
  statusFieldName: string;
  stacked?: boolean;
  withLabel?: boolean;
  formSubmitAnalyticsPayload?: string;
}

interface State {
  isOpen: boolean;
  newStatus: IncidentComponentStatus;
  isOverridden: boolean;
  options: StatusPickerOption[];
}

interface StatusPickerOption {
  icon: string;
  label: string;
  value: string;
}

export default class ComponentStatusPicker extends React.Component<
  Props,
  State
> {
  static defaultProps = {
    stacked: false,
    withLabel: true,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      newStatus: props.newStatus || props.status,
      isOverridden: props.isOverridden,
      options: this.formatOptions(),
    };
  }

  componentWillReceiveProps(newProps: Props) {
    const newStatus = newProps.newStatus || this.state.newStatus;
    const isOverridden = newProps.isOverridden;

    this.setState({
      newStatus,
      isOverridden,
    });
  }

  formatOptions() {
    return StatusSelectValues[0].items.map(
      (status): StatusPickerOption => ({
        value: status.value as string,
        label: status.content as string,
        icon: status.elemBefore as string,
      }),
    );
  }

  formatOptionLabel(option: StatusPickerOption) {
    return (
      <div
        className={
          this.isDisabled
            ? styles.iconicSelectOptionDisabled
            : styles.iconicSelectOption
        }
      >
        {option.icon}
        <span style={{ paddingLeft: 8, paddingBottom: 0 }}>{option.label}</span>
      </div>
    );
  }

  setOverride = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setOverride && this.props.setOverride(event.target.checked);
    this.setState({
      isOverridden: event.target.checked,
    });

    if (!event.target.checked) {
      this.props.onSelected && this.props.onSelected(this.props.basedOnStatus!);
      this.setState({
        newStatus: this.props.basedOnStatus!, // reset to server status on unselected
      });
    }
  };

  onChange = (val: StatusPickerOption) => {
    this.setState({
      newStatus: val.value as IncidentComponentStatus,
    });
    this.props.onSelected &&
      this.props.onSelected(val.value as IncidentComponentStatus);

    window.analyticsClient.then((analyticsClient: any) => {
      if (this.props.formSubmitAnalyticsPayload) {
        const analyticsPayload = JSON.parse(
          this.props.formSubmitAnalyticsPayload,
        );
        analyticsPayload.attributes.status = val.value;
        analyticsClient.sendUIEvent(analyticsPayload);
      }
    });
  };

  overrideStatus = () => {
    return this.props.isBasedOn ? (
      <Checkbox
        className={styles.overrideStatusADG3}
        label={<span className="override-component-label">Override</span>}
        onChange={this.setOverride}
        isChecked={this.state.isOverridden}
      />
    ) : null;
  };

  renderSelect = () => (
    <Select
      onChange={this.onChange}
      isDisabled={this.isDisabled}
      options={this.formatOptions()}
      value={this.selected}
      formatOptionLabel={this.formatOptionLabel}
      isSearchable={false}
      classNamePrefix="status-dropdown"
    />
  );

  get isDisabled() {
    return this.props.isBasedOn ? !this.state.isOverridden : false;
  }

  get selected() {
    const status =
      this.props.isBasedOn && !this.state.isOverridden
        ? this.props.basedOnStatus
        : this.state.newStatus || this.props.status || this.props.basedOnStatus;
    return this.state.options.find(option => option.value === status);
  }

  hiddenField() {
    if (!this.props.visible) {
      return null;
    }
    return (
      <input
        type="hidden"
        name={this.props.statusFieldName}
        value={
          this.props.isBasedOn
            ? this.state.isOverridden
              ? this.state.newStatus
              : ''
            : this.state.newStatus
        }
      />
    );
  }

  render() {
    const outerClass = cx(
      styles.statusDropdown,
      this.props.visible ? null : styles.isHidden,
      this.props.stacked ? styles.columnReversed : null,
    );

    const innerClass = cx(
      'incident-status-dropdown',
      'component-status-picker', // must leave this handle for capybara
      { 'incident-status-dropdown-open': this.state.isOpen },
    );

    return (
      <div className={outerClass}>
        {this.overrideStatus()}
        <div className={innerClass}>
          {this.hiddenField()}
          {this.props.withLabel ? (
            <Field name="incident-status" label="Status">
              {this.renderSelect}
            </Field>
          ) : (
            this.renderSelect()
          )}
        </div>
      </div>
    );
  }
}
